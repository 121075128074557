// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-imgs {
    width: 100%;
}

.gallery-carousel-imgs{
    width: 85%;
    margin-left: 7%;
}

#gyro{
    height: 89%;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Gallery.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,WAAW;AACf","sourcesContent":[".gallery-imgs {\n    width: 100%;\n}\n\n.gallery-carousel-imgs{\n    width: 85%;\n    margin-left: 7%;\n}\n\n#gyro{\n    height: 89%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
