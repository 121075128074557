// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#footer-container{
    background-color: #f9e4bc;
    height: 30%;
    box-shadow: 0px -4px 5px black;
    padding-bottom: 25px;
}

#footer-logo{
    width: 175px;
    position: relative;
    top: 30%;
}

#phoneNumber{
    text-decoration: none;
    color: black;
}

#admin-login{
    color: black;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;IACX,8BAA8B;IAC9B,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,qBAAqB;AACzB","sourcesContent":["#footer-container{\n    background-color: #f9e4bc;\n    height: 30%;\n    box-shadow: 0px -4px 5px black;\n    padding-bottom: 25px;\n}\n\n#footer-logo{\n    width: 175px;\n    position: relative;\n    top: 30%;\n}\n\n#phoneNumber{\n    text-decoration: none;\n    color: black;\n}\n\n#admin-login{\n    color: black;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
