// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#lunch-menu-body, #dinner-menu-body, #kids-menu-body {
    color : #1a2536;
    padding-top: 50px;
}



.course-headers{
    font-size: 300%;
    font-weight: bold;
}

#apps-div, #starters-div , #sandwiches-and-pies-div, #bowls-div, #desserts-div, #comforts-div, #entrees-div, #kids-menu-div, #kids-desserts-div {
    border: 5px solid #1a2536;
}

#individual-menu-items {
    font-size: xx-large;
    padding: 0 15% 0 15%;
    margin: 5% 0%;
}

#menu-item-desc{
    font-size: 70%;
    font-weight: 100;
    text-align: left;
}

#menu-item-price{
    text-align: right;
    font-size: smaller;
    margin: auto;
}

#menu-item-title{
    text-align: left;
    font-size: 125%;
}

#switch-menu{
    text-decoration: underline;
    cursor: pointer;
}

.subs {
    font-size: 54%;
}

.side-edit-inputs{
    width: 80%;
}

#edit-sides-row{
    display: none;
}

.sides-to-edit{
    display: none;
}

#menu {
    font-size: 60px;
    font-weight: bolder;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Menu.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,iBAAiB;AACrB;;;;AAIA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB","sourcesContent":["#lunch-menu-body, #dinner-menu-body, #kids-menu-body {\n    color : #1a2536;\n    padding-top: 50px;\n}\n\n\n\n.course-headers{\n    font-size: 300%;\n    font-weight: bold;\n}\n\n#apps-div, #starters-div , #sandwiches-and-pies-div, #bowls-div, #desserts-div, #comforts-div, #entrees-div, #kids-menu-div, #kids-desserts-div {\n    border: 5px solid #1a2536;\n}\n\n#individual-menu-items {\n    font-size: xx-large;\n    padding: 0 15% 0 15%;\n    margin: 5% 0%;\n}\n\n#menu-item-desc{\n    font-size: 70%;\n    font-weight: 100;\n    text-align: left;\n}\n\n#menu-item-price{\n    text-align: right;\n    font-size: smaller;\n    margin: auto;\n}\n\n#menu-item-title{\n    text-align: left;\n    font-size: 125%;\n}\n\n#switch-menu{\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.subs {\n    font-size: 54%;\n}\n\n.side-edit-inputs{\n    width: 80%;\n}\n\n#edit-sides-row{\n    display: none;\n}\n\n.sides-to-edit{\n    display: none;\n}\n\n#menu {\n    font-size: 60px;\n    font-weight: bolder;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
