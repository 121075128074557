// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#landing-page-images { 
    width: 80%;
    /* height: 78vh; */
}

#carousel-div{
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Welcome.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf","sourcesContent":["#landing-page-images { \n    width: 80%;\n    /* height: 78vh; */\n}\n\n#carousel-div{\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
