import React from 'react'

const TastingRoom = () => {
  return (
    <>
        <h1>TastingRoom</h1>
    </>
  )
}

export default TastingRoom