// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
    width: 50%;
    height: 75%;
    margin: 5% 0;
    font-size: xx-large;
    text-align: center;
    text-shadow: 2px 1px black;
    background-color: rgb(71, 110, 162);
    color: white;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/AdminPage.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;IAClB,0BAA0B;IAC1B,mCAAmC;IACnC,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".buttons {\n    width: 50%;\n    height: 75%;\n    margin: 5% 0;\n    font-size: xx-large;\n    text-align: center;\n    text-shadow: 2px 1px black;\n    background-color: rgb(71, 110, 162);\n    color: white;\n    border-radius: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
