// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#individual-farmers{
    border-top: 2px solid black;
    padding: 5% 0%;
}


`, "",{"version":3,"sources":["webpack://./src/components/LocalFarmer.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,cAAc;AAClB","sourcesContent":["#individual-farmers{\n    border-top: 2px solid black;\n    padding: 5% 0%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
