// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#root {
  background-image: radial-gradient(white, white, rgb(90, 121, 147));
  font-family: "garamond-premier-pro-display", serif;
  font-weight: 400;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AACA;EACE,kEAAkE;EAClE,kDAAkD;EAClD,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":["\n#root {\n  background-image: radial-gradient(white, white, rgb(90, 121, 147));\n  font-family: \"garamond-premier-pro-display\", serif;\n  font-weight: 400;\n  font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
