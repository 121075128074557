// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputs {
    margin-top: 6%;
}

#register-container{ 
    padding: 5% 0% 5%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/CreateNewUser.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".inputs {\n    margin-top: 6%;\n}\n\n#register-container{ \n    padding: 5% 0% 5%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
