// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#aboutLandingPhoto{
    width: 50%;
    border-radius: 3%;
    border: 5px solid maroon;
}

.aboutPics {
    width: 65%;
    border-radius: 3%;
    border: 5px solid maroon;
}


@media (max-width: 765px){

    #aboutLandingPhoto{
        width: 90%;
        border-radius: 3%;
        border: 5px solid maroon;
    }

    #secondRow, #fourthRow, #sixthRow{
        display: flex;
        flex-direction: column-reverse;
    }

    #para2, #para1, #para3, #para4, #para5, #para6{
        text-align: center;
        padding: 5% 5%;
    }

    #img1, #img3, #img5{
        padding-left: 10%;
    }

    #img2, #img4, #img6{
        padding-right: 10%;
    }

    .aboutPics{
        width: 90%;
    }






}`, "",{"version":3,"sources":["webpack://./src/Pages/About.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,iBAAiB;IACjB,wBAAwB;AAC5B;;AAEA;IACI,UAAU;IACV,iBAAiB;IACjB,wBAAwB;AAC5B;;;AAGA;;IAEI;QACI,UAAU;QACV,iBAAiB;QACjB,wBAAwB;IAC5B;;IAEA;QACI,aAAa;QACb,8BAA8B;IAClC;;IAEA;QACI,kBAAkB;QAClB,cAAc;IAClB;;IAEA;QACI,iBAAiB;IACrB;;IAEA;QACI,kBAAkB;IACtB;;IAEA;QACI,UAAU;IACd;;;;;;;AAOJ","sourcesContent":["#aboutLandingPhoto{\n    width: 50%;\n    border-radius: 3%;\n    border: 5px solid maroon;\n}\n\n.aboutPics {\n    width: 65%;\n    border-radius: 3%;\n    border: 5px solid maroon;\n}\n\n\n@media (max-width: 765px){\n\n    #aboutLandingPhoto{\n        width: 90%;\n        border-radius: 3%;\n        border: 5px solid maroon;\n    }\n\n    #secondRow, #fourthRow, #sixthRow{\n        display: flex;\n        flex-direction: column-reverse;\n    }\n\n    #para2, #para1, #para3, #para4, #para5, #para6{\n        text-align: center;\n        padding: 5% 5%;\n    }\n\n    #img1, #img3, #img5{\n        padding-left: 10%;\n    }\n\n    #img2, #img4, #img6{\n        padding-right: 10%;\n    }\n\n    .aboutPics{\n        width: 90%;\n    }\n\n\n\n\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
