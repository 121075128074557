// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.local-farmer-rows:not(:last-child){
    border-bottom: 5px solid black;
}

.websiteUrls{
    color: black;
    text-decoration: none;
    cursor: pointer;
}


`, "",{"version":3,"sources":["webpack://./src/Pages/LocalFarmers.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[".local-farmer-rows:not(:last-child){\n    border-bottom: 5px solid black;\n}\n\n.websiteUrls{\n    color: black;\n    text-decoration: none;\n    cursor: pointer;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
