// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-cols{
    display: none;
}

#addAnotherButton{
    cursor: pointer;
}

.allergyWarnings{
    font-size: 60%;
}`, "",{"version":3,"sources":["webpack://./src/components/MenuItem.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".button-cols{\n    display: none;\n}\n\n#addAnotherButton{\n    cursor: pointer;\n}\n\n.allergyWarnings{\n    font-size: 60%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
